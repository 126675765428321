function renderSteps(steps, filter, totalSteps) {
  for (const step of steps) {
    if (step.classList.contains("step-initial")) continue;
    if (step.classList.contains("step-final")) {
      const no = step.querySelector("header>small>data");
      no.value = totalSteps;
      no.textContent = totalSteps;
      continue;
    }
    const selectedStep = step.classList.contains(filter);
    for (const el of step.querySelectorAll("fieldset")) {
      el.disabled = !selectedStep;
    }
    step.ariaHidden = !selectedStep;
  }
}

/**
 * @param {Window} globalScope
 * @returns {ShopSteps}
 */
export function defShopSteps({ HTMLElement, customElements }) {
  class ShopSteps extends HTMLElement {
    static register(tagName = "shop-steps") {
      this.tagName = tagName;
      customElements.define(tagName, this);
    }

    async connectedCallback() {
      this.steps = this.querySelectorAll(".step");
      this.addEventListener("change", (e) => {
        if (e.target.name === "sampleType") {
          this.handleSampleTypeChange(e);
        }
      });
      this.addEventListener("step-change", (e) => {
        this.dataset.currentStep = e.detail;
        if (e.detail === "step-3") {
          const li = this.querySelector(".step-2 li:has(input:checked)");
          this.querySelector(".step-3").dataset.restriction = li.dataset.restriction;
        }
      });

      for (const btn of this.querySelectorAll("button[data-next-step]")) {
        btn.addEventListener("click", function () {
          const validate = this.dataset.checkValidity;
          if (validate && !this.form[validate][0].reportValidity()) {
            return false;
          }
          this.dispatchEvent(
            new CustomEvent("step-change", {
              detail: this.dataset.nextStep,
              bubbles: true,
            }),
          );
        });
      }
    }

    handleSampleTypeChange(e) {
      this.dataset.filter = `step-${e.target.value}`;
      const totalSteps = e.target.dataset.totalSteps;
      this.dataset.totalSteps = totalSteps;
      this.style.setProperty("--total-steps", Number.parseInt(totalSteps, 10));
      renderSteps(this.steps, this.dataset.filter, totalSteps);
    }
  }

  return ShopSteps;
}

if (window?.customElements) {
  defShopSteps(window).register();
}
